import { Component, OnInit } from '@angular/core';
import { AllService } from './all.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Babienet';
  constructor(
    private __router: Router,
    private __allService: AllService
  ) { }
  ngOnInit(){
    this.__allService.randomName('f').subscribe();
    this.__router.navigate(['']);
  }
}
