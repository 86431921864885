import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
import { AllService } from '../all.service';
import { ToasterService } from '../toaster.service';
declare var jQuery:any;

@Component({
  selector: 'app-az-meaning',
  templateUrl: './az-meaning.component.html',
  styleUrls: ['./az-meaning.component.css','../../assets/css/freelancer.min.css'],
  encapsulation: ViewEncapsulation.None
})

export class AzMeaningComponent implements OnInit {
  suggestedName = new FormControl('');
  letter:string = '';
  genderTypeName:string = '';
  gender:string = 'f';
  listingDetail:any = ['','',''];
  listlength:number;
  recentAddedGirls:any;
  recentAddedBoys:any;
  page:number=1;
  prev_page:number=0;
  next_page:number=1;
  wheelSearch:[];
  bgClass:string = 'bg-pink';
  constructor(
    private _activeRouter: ActivatedRoute,
    private __footer: FooterLayoutComponent,
    private __allService: AllService,
    private __toasterService: ToasterService,
    private __router:Router
  ) { }

  ngOnInit() {
    this._activeRouter.params.subscribe(routeParams => {
      if(routeParams.gender == "female") { this.gender = "f"; this.bgClass = 'bg-pink'; }
      else if(routeParams.gender == "male") { this.gender = "m"; this.bgClass = 'bg-blue'; }
      else if(routeParams.gender == "any") { this.gender = "any"; }

      this.letter = routeParams.letter;
      this.page = routeParams.page;
      this.name_listing(this.gender, this.letter, this.page);
      this.recentaddedgirls();
      this.recentaddedboys();
      this.most_searched_wheel(this.gender);
    });
  }

  pagination(page:number){
    var g:string = ';'
      if(this.gender == "f") { g = "female"; }
      else if(this.gender == "m") { g = "male"; }
      else { g = "any"; }
    this.__router.navigate(['/letter/'+g+'/'+this.letter+'/'+page]);
  }

  recentaddedgirls(){
    this.__allService.recentaddedgirls()
      .subscribe(
        (resp :any) => {
          this.recentAddedGirls = resp;
        },
        (error) => { console.log(error) }
      );
  }

  recentaddedboys(){
    this.__allService.recentaddedboys()
      .subscribe(
        (resp :any) => {
          this.recentAddedBoys = resp;
        },
        (error) => { console.log(error) }
      );
  }
  
  name_listing(gender:string, letter:string, page:number){
    this.genderTypeName = gender;
    console.log('test',this.genderTypeName);
    this.__allService.name_listing(gender, letter, page, 100)
      .subscribe(
        (resp :any) => {
          if(resp.status == 1){
            this.listingDetail = resp.data;
            this.prev_page = resp.prev_page;
            this.next_page = resp.next_page;
          }
        },
        (error) => { console.log(error) }
      );
  }

  addName(form: NgForm){
    const s_name = this.suggestedName.value;
    const s_gender = form.value.s_gender;
    const s_meaning = form.value.s_meaning;

    if(s_name && s_gender && s_meaning){
      this.__allService.addName(s_name, s_gender, s_meaning)
      .subscribe(
        (resp:any) => {
          form.reset();
          jQuery('#addNameModal').modal('hide');
          this.suggestedName = new FormControl('');
          this.__toasterService.show_toaster('Name added successfully.');
        },
        (error) => {console.log(error)}
      );
    }
  }

  go(){
    const s_name = this.suggestedName.value;
    if(s_name){
      jQuery('#addNameModal').modal('show');
    }
  }
  
  most_searched_wheel(gender:string){
  	this.__allService.most_searched_wheel(gender)
      .subscribe(
        (resp :any) => {
          this.wheelSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

  change_gender(gender:string){
    this.gender = gender;
  }

}
