import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
import { AllService } from '../all.service'

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.css','../../assets/css/style.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderLayoutComponent implements OnInit {
  searchName = new FormControl('');
  constructor(
    private __router: Router,
    private __allService: AllService,
    private __footer: FooterLayoutComponent
  ) { }

  ngOnInit() {
  }

  search(){
    var queryP = { 'name':''};
    const searchName = (this.searchName.value) ? this.searchName.value : this.__allService.searchName;
    if(searchName.length >= 1){
      queryP.name = searchName;
      this.__router.navigate(['/search'], {queryParams: queryP});
    }
  }

  logout(){
    this.__allService.logout();
  }
}
