import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, count } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppGlobals } from './app.global';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery:any;

@Injectable({
  providedIn: 'root'
})
export class AllService {
  APIBASEURL: string; 
  HEADERS: any;
  totalFav:number = 0;
  constructor(
    private _httpClient: HttpClient,
    private __router: Router,
    private ngxService: NgxUiLoaderService
  ) { 
    this.APIBASEURL = AppGlobals.api_base_url;
    this.HEADERS    = new Headers({'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' });
  }
  gender:string = 'f';
  all_random_names:any;  
  wheel_names:any = [];
  winning_name_id:any;
  winning_name:string;
  display_name:string;
  hidden_class:string = 'd-none';
  n1:any;
  
  userName:string = 'User';
  fav_names:any = [];
  
  name_id:string='';
  meaning:string='';

  rating:number = 0;
  avg_rating_of_name:number = 0;

  searchName:string = '';

  beforeSpinFn(){
    this.ngxService.start();
    this.winning_name_id = this.n1[3].id;
    this.display_name = this.n1[3].name;
    this.hidden_class = 'd-none';
    this.ngxService.stop();
  }

  afterSpinFn(){
    this.ngxService.stop();
    this.winning_name_id = this.n1[3].id;
    this.display_name = this.n1[3].name;
    this.hidden_class = '';
    this.prepare_wheel_data();
    this.wheel_search_update(this.gender, this.winning_name_id).subscribe();
  }
  
  prepare_wheel_data(){
    this.n1 = this.get_n_random(this.all_random_names, 8);
    for (var n in this.n1) {
      this.wheel_names[n] = this.n1[n].name;
    }
    this.winning_name = this.n1[3].name;
  }

  wheel_search_update(gender:string, id:number){
    var url = this.APIBASEURL + '/scrap/wheel_search_update';
    var dataToPost = {
      'gender': gender,
      'id': id
    };
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  randomName(gender:string){
    var url = this.APIBASEURL + '/scrap/randomName?gender='+gender;
    return this._httpClient.get( url ).pipe(
      map(
        (resp:any) => {
          this.all_random_names = resp.data;
          this.prepare_wheel_data();   
        }
      )
    );
  }

  get_n_random(arr, n) {
		var result = new Array(n),
			len = arr.length,
			taken = new Array(len);
		if (n > len)
			throw new RangeError("getRandom: more elements taken than available");
		while (n--) {
			var x = Math.floor(Math.random() * len);
			result[n] = arr[x in taken ? taken[x] : x];
			taken[x] = --len in taken ? taken[len] : len;
		}
		return result;
	}

  login(username: string, password: string){
    var url = this.APIBASEURL + '/user/login?username='+username+'&password='+password;
    return this._httpClient.get( url );
  }

  user(username:string, password:string, email:string, mobileNumber:string){
    var url = this.APIBASEURL + '/user/user';
    var dataToPost = {
      'username': username,
      'password': password,
      'email': email,
      'mobileNumber':mobileNumber
    };
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  isAuthenticated(){
    const token = (sessionStorage.getItem('token')) ? true : false;
    if(token){
        return true;
    }else{
        return false;
    }
  }

  logout(){
    sessionStorage.setItem('token', '');
    sessionStorage.setItem('userName', '');
    this.__router.navigate(['']);
  }

  name_meaning(id: string){
    var url = this.APIBASEURL + '/scrap/name?id='+id;
    return this._httpClient.get( url ).pipe(
      map(
        (resp:any) => {
          if(resp.status == 1){
            this.name_id = resp.data[0].id;
            this.display_name = resp.data[0].name;
            this.meaning =resp.data[0].meaning;
          }
          return resp;
        }
      )
    );
  }

  getUserToken(){
    return (sessionStorage.getItem('token')) ? sessionStorage.getItem('token') : '';
  }

  getUserName(){
    return (sessionStorage.getItem('userName')) ? sessionStorage.getItem('userName') : '';
  }

  add_rating(rating:number, name_id:string){
    var url = this.APIBASEURL + '/rating/add_rating';
    var dataToPost = {
      "user"  : this.getUserToken(),
      "name"  : name_id,
      "rating": rating
    };
    this.avg_rating_of_name = rating;
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  name_listing(gender:string, letter:string, page:number, limit:number){
    var url = this.APIBASEURL + '/scrap/letterNGender';
    var dataToPost = {
      'gender': gender,
      'letter': letter,
      'page':page,
      'limit':limit
    };
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  avg_rating(name:string){
    this.name_id = name;
    this.avg_rating_of_name = 0;
    this.rating = 0;
    var url = this.APIBASEURL + '/rating/avg_rating?name='+name;
    return this._httpClient.get( url ).pipe(
      map(
        (resp:any) => {
          if(resp.status == 1){
            if(resp.data.length > 0){
              if(typeof resp.data[0].rating  !== 'undefined' && resp.data[0].rating > 0){
                this.avg_rating_of_name = resp.data[0].rating;
                this.rating = resp.data[0].rating;
              }
            }
          }
        }
      )
    );
  }

  live_search(){
    var url = this.APIBASEURL + '/scrap/live_search';
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let live_search = [];
          let response = resp.data;
          if(response.length > 0){
            const loop = (response.length > 3) ? 5 : response.length;
            for(let i = 0; i<loop; i++){
              live_search[i] = response[i].name;
            }
          }
          return live_search;
        }
      )
    );
  }

  most_searched_wheel(gender:string){
    var url = this.APIBASEURL + '/scrap/most_searched_wheel?gender='+gender;
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let wheel_search = [];
          let response = resp.data;
          if(response.length > 0){
            const loop = (response.length > 3) ? 5 : response.length;
            for(let i = 0; i<loop; i++){
              wheel_search[i] = response[i].name;
            }
          }
          return wheel_search;
        }
      )
    );
  }

  topbabynamegirls(){
    var url = this.APIBASEURL + '/scrap/top_baby_name/?duration=month&gender=f';
    return this._httpClient.get( url );
   
  }

  topbabynameboys(){
    var url = this.APIBASEURL + '/scrap/top_baby_name/?duration=month&gender=m';
    return this._httpClient.get( url );
   
  }

  topbabynamegirlstoday(){
    var url = this.APIBASEURL + '/scrap/top_baby_name/?duration=today&gender=f';
    return this._httpClient.get( url );
   
  }
  
  topbabynameboystoday(){
    var url = this.APIBASEURL + '/scrap/top_baby_name/?duration=today&gender=m';
    return this._httpClient.get( url );
   
  }

  topbabynameany(){
    var url = this.APIBASEURL + '/scrap/top_baby_name/?duration=month&gender=any';
    return this._httpClient.get( url );
   
  }

  recentaddedgirls(){
    var url = this.APIBASEURL + '/scrap/recent_added/?gender=f';
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let Names = [];
          let response = resp.data;
          if(response.length > 0){
            var loop = (response.length > 8) ? 8 : response.length;
            for(let i = 0; i<loop; i++){
              Names.push(response[i].name);
            }
          }
          return Names;
        }
      )
    );
  }

  recentaddedboys(){
    var url = this.APIBASEURL + '/scrap/recent_added/?gender=m';
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let Names = [];
          let response = resp.data;
          if(response.length > 0){
            var loop = (response.length > 8) ? 8 : response.length;
            for(let i = 0; i<loop; i++){
              Names.push(response[i].name);
            }
          }
          return Names;
        }
      )
    );
  }

  recentaddedany(){
    var url = this.APIBASEURL + '/scrap/recent_added/?gender=any';
    return this._httpClient.get( url );
  }

  live_users(){
    var url = this.APIBASEURL + '/scrap/live_users';
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let count:number = 0;
          if('status' in resp && 'count' in resp.data){
            count = resp.data.count;
          }
          return count;
        }
      )
    );
  }

  favouriteName(user_id:string){
    var url = this.APIBASEURL + '/user/favouriteName?id='+user_id;
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          this.fav_names = resp;
          this.totalFav = resp.length;
          return resp;
        }
      )
    );
  }

  addfavouriteName(user_id:string, name_id:string){
    var url = this.APIBASEURL + '/user/addFavouriteName';
    var dataToPost = {
      'id': user_id,
      'name': name_id
    };
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  latest_addition(){
    var url = this.APIBASEURL + '/scrap/latestEdition';
    return this._httpClient.get( url );
  }

  addName(name:string, gender:string, meaning:string){
    var url = this.APIBASEURL + '/scrap/addName';
    var dataToPost = {
      'name': name,
      'gender': gender,
      'meaning': meaning,
      'name_usage':'',
      'translation':''
    };
    return this._httpClient.post(url, dataToPost, this.HEADERS);
  }

  search(searchName:string){
    this.searchName = searchName;
    var url = this.APIBASEURL + '/scrap/test_filter/?keyword='+searchName;
    return this._httpClient.get( url ).pipe(
      map(
        (resp: any) => {
          let searchResultNames = [];
          if(resp.status == 1 && resp.data.length > 0){
            searchResultNames = resp.data;
          }
          return searchResultNames;
        }
      )
    );
  }
}
