//Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

//Other
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxWheelModule } from 'ngx-wheel';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

//Components
import { AppComponent } from './app.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { FooterLayoutComponent } from './footer-layout/footer-layout.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { AZComponent } from './a-z/a-z.component';
import { AzHeaderComponent } from './az-header/az-header.component';
import { AzMeaningComponent } from './az-meaning/az-meaning.component';
import { WheelOfFortuneComponent } from './wheel-of-fortune/wheel-of-fortune.component';
import { ToasterService } from './toaster.service';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderLayoutComponent,
    FooterLayoutComponent,
    HomeComponent,
    AZComponent,
    AzHeaderComponent,
    AzMeaningComponent,
    WheelOfFortuneComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgxWheelModule,
    NgxUiLoaderModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [ToasterService, FooterLayoutComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
