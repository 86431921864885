import { Injectable } from '@angular/core';
declare var jQuery:any;

@Injectable()
export class ToasterService {

    show_toaster(message:string, icon:string = "success", heading:string = ""){
        //heading: Success, Warning, Error, Information
        //icon: success, warning, error, info
        jQuery.toast({
            heading: heading,
            text: message,
            showHideTransition: 'slide',
            hideAfter: 5000,
            position: 'top-right',
            icon: icon
        })
    }

}