import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
import { AllService } from '../all.service';

@Component({
  selector: 'app-az-header',
  templateUrl: './az-header.component.html',
  styleUrls: ['./az-header.component.css','../../assets/css/freelancer.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AzHeaderComponent implements OnInit {
  totalFav:number = 0;
  constructor(
    private __allService: AllService,
    private __footer: FooterLayoutComponent
  ) { }

  ngOnInit() {
  }
  
  logout(){
    this.__allService.logout();
  }
}
