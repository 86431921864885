import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from '../all.service';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
declare var jQuery:any;

@Component({
  selector: 'app-a-z',
  templateUrl: './a-z.component.html',
  styleUrls: ['./a-z.component.css','../../assets/css/freelancer.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AZComponent implements OnInit {
  gender:string = 'f';
  top_search:any;
  topBabynameGirls:any;
  topBabynameBoys:[];
  topBabynameBoysToday:[];
  topBabynameGirlsToday:any;
  liveSearch:[];
  wheelSearch:[];
  constructor(
    private __allService: AllService,
    private __router:Router,
    private __footer: FooterLayoutComponent
  ) { }

  ngOnInit() {
    this.live_search();
    this.most_searched_wheel(this.gender);
    this.top_baby_names_boys_today();
    this.top_baby_names_girls_today();
  }
  
  live_search(){
  	this.__allService.live_search()
      .subscribe(
        (resp :any) => {
          this.liveSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

  most_searched_wheel(gender:string){
  	this.__allService.most_searched_wheel(gender)
      .subscribe(
        (resp :any) => {
          this.wheelSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

  top_baby_names_girls_today(){
    this.__allService.topbabynamegirlstoday()
      .subscribe(
        (resp :any) => {
          /* 
          response data format
          {
            "status": 1,
            "message": "List of Top Popular Name",
            "data": [
              {
                "id": "16012",
                "name": "KATRIONA",
                "gender": "f",
                "name_usage": "English (Rare)",
                "meaning": "Variant of CATRIONA.",
                "translation": "",
                "visitors": "3",
                "create_date": "2019-03-09 14:06:24.44",
                "last_visit_date": "1557061287"
              },
              {
                "id": "208",
                "name": "NOMUSA",
                "gender": "f",
                "name_usage": "Southern African, Ndebele",
                "meaning": "Means \"merciful\" in Ndebele.",
                "translation": "  ",
                "visitors": "2",
                "create_date": "2019-03-09 14:06:24.44",
                "last_visit_date": "1557061489"
              },
              {
                "id": "36890",
                "name": "FAYE",
                "gender": "f",
                "name_usage": "English",
                "meaning": "Variant of FAY.",
                "translation": "",
                "visitors": "1",
                "create_date": "2019-03-09 14:06:24.44",
                "last_visit_date": "1557061523"
              },
              {
                "id": "29807",
                "name": "AGNETA",
                "gender": "f",
                "name_usage": "Swedish",
                "meaning": "Swedish variant of AGNES.",
                "translation": " ",
                "visitors": "1",
                "create_date": "2019-03-09 14:06:24.44",
                "last_visit_date": "1557061544"
              },
              {
                "id": "26644",
                "name": "BASIA  (1)",
                "gender": "f",
                "name_usage": "Polish",
                "meaning": "Polish diminutive of BARBARA.",
                "translation": "",
                "visitors": "2",
                "create_date": "2019-03-09 14:06:24.44",
                "last_visit_date": "1557061556"
              }
            ]
          }
          */
          this.topBabynameGirlsToday = resp.data;
        },
        (error) => { console.log(error) }
      );
  
  }

  top_baby_names_boys_today(){
    this.__allService.topbabynameboystoday()
      .subscribe(
        (resp :any) => {
          this.topBabynameBoysToday = resp.data;
        },
        (error) => { console.log(error) }
      );
  
  }

  change_gender(gender:string){
    this.gender = gender;
    this.most_searched_wheel(this.gender);
  }

}
