import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from '../all.service';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
declare var jQuery:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css','../../assets/css/style.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
  liveSearch = [];
  wheelSearch = [];
  topBabynameGirls:any;
  topBabynameBoys:any;
  constructor(
    private __allService: AllService,
    private __router:Router,
    private __footer: FooterLayoutComponent
  ) { }

  ngOnInit() {
    this.live_search();
    this.most_searched_wheel(this.__allService.gender);
    this.top_baby_names_girls();
    this.top_baby_names_boys();
    this.__allService.randomName('f').subscribe();
  }
  
  live_search(){
  	this.__allService.live_search()
      .subscribe(
        (resp :any) => {
          this.liveSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

  most_searched_wheel(gender:string){
  	this.__allService.most_searched_wheel(gender)
      .subscribe(
        (resp :any) => {
          this.wheelSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

  top_baby_names_girls(){
    this.__allService.topbabynamegirls()
      .subscribe(
        (resp :any) => {
          this.topBabynameGirls = resp.data;
        },
        (error) => { console.log(error) }
    );
  }

  top_baby_names_boys(){
  this.__allService.topbabynameboys()
      .subscribe(
        (resp :any) => {
          this.topBabynameBoys = resp.data;
        },
        (error) => { console.log(error) }
    );
  }  
}
