import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AllService } from '../all.service'
import { ToasterService } from '../toaster.service';
declare var jQuery:any;

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterLayoutComponent implements OnInit {
  constructor(
    private __allService: AllService,
    private __toasterService: ToasterService,
    private __router: Router
  ) { }
  temp_rating:number=0;
  avg_rating_of_name:number = 0;

  ngOnInit() {
  }

  onLogin(form: NgForm){
    const username = form.value.username;
    const password = form.value.password;
    if(username && password){
      this.__allService.login(username, password)
      .subscribe(
        (resp :any) => {
          if(resp.status == 1){
            sessionStorage.setItem('token', resp.data.id);
            sessionStorage.setItem('userName', resp.data.username);
            form.reset();
            //this.__router.navigate(['']);
            jQuery('#loginModal').modal('hide');
            this.__allService.favouriteName(resp.data.id).subscribe();
            this.__toasterService.show_toaster('Successfully Logged In.');
          }
        },
        (error) => { console.log(error) }
      );
    }
  }

  onRegister(form: NgForm){
    const username = form.value.username;
    const password = form.value.password;
    const email = form.value.email;
    const mobileNumber = '5555555555';//form.value.mobileNumber;
    if(username && password && email){
      this.__allService.user(username, password, email, mobileNumber)
      .subscribe(
        (resp :any) => {
          if(resp.status == 1){
            form.reset();
            this.__router.navigate(['']);
            jQuery('#registerModal').modal('hide');
            this.__toasterService.show_toaster('Registered Successfully.');
          }
        },
        (error) => { console.log(error) }
      );
    }
    
  }
  
  favouriteName(){
    if(this.__allService.isAuthenticated()){
      this.__allService.favouriteName(this.__allService.getUserToken())
      .subscribe(
        (resp:any) => {
          jQuery("#favsModal").modal('show');
        },
        (error) => { console.log(error) }
      );
    }else{
      jQuery('#loginModal').modal('show');
    }
  }

  addfavouriteName(name_id:string){
    if(this.__allService.isAuthenticated()){
      this.__allService.addfavouriteName(this.__allService.getUserToken(), name_id)
      .subscribe(
        (resp:any) => {
          if(resp.status == 1){
            this.__toasterService.show_toaster(resp.message);
          } else {
            this.__toasterService.show_toaster(resp.message, 'warning');
          }
        },
        (error) => { console.log(error) }
      );
    }else{
      jQuery('#loginModal').modal('show');
    }
  }

  open_rating_modal(name_id:string){
    if(this.__allService.isAuthenticated()){
      this.__allService.avg_rating(name_id)
        .subscribe(
          (resp:any) => {
            jQuery('#rateModal').modal('show');
          }
        );
    }else{
      jQuery('#loginModal').modal('show');
    }
  }

  //mouse enter on rating
  mse_on_rating(rating_given:number){
    this.__allService.rating = rating_given;
  }

  //mouse leave from rating
  msl_from_rating(rating_given:number){
    this.__allService.rating = (this.__allService.avg_rating_of_name > 0) ? this.__allService.avg_rating_of_name : rating_given;
  }

  add_rating(rating:number, name_id:string){
    if(this.__allService.isAuthenticated()){
      this.__allService.add_rating(rating, name_id)
      .subscribe(
        (resp :any) => {
          this.__toasterService.show_toaster('Succefully Rated.');
          jQuery('#rateModal').modal('hide');
        },
        (error) => { console.log(error) }
      );
    }else{
      jQuery('#loginModal').modal('show');
    }
  }

  meaning(name_id:string){
    this.__allService.name_meaning(name_id)
      .subscribe(
        (resp :any) => {
          if(resp.status == 1){
            jQuery("#meanModal").modal('show');
          }
        },
        (error) => { console.log(error) }
      );
  }

}
