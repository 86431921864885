import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
import { AllService } from '../all.service';
import { ToasterService } from '../toaster.service';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var jQuery:any;

@Component({
  selector: 'app-wheel-of-fortune',
  templateUrl: './wheel-of-fortune.component.html',
  styleUrls: ['./wheel-of-fortune.component.css','../../assets/css/style.css'],
  encapsulation: ViewEncapsulation.None
})
export class WheelOfFortuneComponent implements OnInit {
  liveSearch:string = '';
  gender:string = 'f';
  
  live_user:number=0;
  
  constructor(
    private __allService: AllService,
    private __footer: FooterLayoutComponent,
    private __toasterService: ToasterService,
    private deviceService: DeviceDetectorService
  ) { 
    this.__allService.hidden_class = 'd-none';
  }
  
  ngOnInit() {
   this.liveusers();
   this.live_search();
   this.__allService.randomName('f').subscribe();
   const isMobile = this.deviceService.isMobile();
   const isDesktopDevice = this.deviceService.isDesktop();
  }

  live_search(){
  	this.__allService.live_search()
      .subscribe(
        (resp :any) => {
          this.liveSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }
  
  liveusers(){
      this.__allService.live_users()
        .subscribe(
          (resp :any) => {
            this.live_user = resp;
          },
          (error) => { console.log(error) }
        );
  }

  open_fav_modal(){
    if(this.__allService.isAuthenticated()){
      jQuery('#rateModal').modal('show');
    }else{
      jQuery('#loginModal').modal('show');
    }
  }

  change_gender(gender:string){
    this.__allService.gender = gender;
    this.__allService.randomName(gender).subscribe();
    this.__allService.hidden_class = 'd-none';
  }
}
