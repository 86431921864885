import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AZComponent } from './a-z/a-z.component';
import { AzMeaningComponent } from './az-meaning/az-meaning.component';
import { WheelOfFortuneComponent } from './wheel-of-fortune/wheel-of-fortune.component';
import { SearchComponent } from './search/search.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'game', component: WheelOfFortuneComponent },
  { path: 'a-z', component: AZComponent },
  { path: 'letter/:gender/:letter/:page', component: AzMeaningComponent },
  { path: 'search', component: SearchComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
