import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FooterLayoutComponent } from '../footer-layout/footer-layout.component';
import { AllService } from '../all.service'
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
  liveSearch = [];
  searchName:string = '';
  searchResultNames = [];
  searchResultNamesCount:number = 0;
  constructor(
    private __allService: AllService,
    private __footer: FooterLayoutComponent,
    private _activeRouter: ActivatedRoute,
  ) { }

  ngOnInit() {
    this._activeRouter.queryParams.subscribe(params => {
      this.searchName = (params['name']) ? params['name'] : '';
      this.live_search();
      this.search(this.searchName);
    });
  }

  search(searchName:string){
    this.__allService.search(searchName)
      .subscribe(
        (resp) => {
          this.searchResultNames = resp;
          this.searchResultNamesCount = resp.length;
        }
      );
  }

  live_search(){
  	this.__allService.live_search()
      .subscribe(
        (resp :any) => {
          this.liveSearch = resp;
        },
        (error) => { console.log(error) }
    );
  }

}
